import { createSlice } from "@reduxjs/toolkit";
import {
	editRetailerIndents,
	getRetailerIndentByID,
	getRetailerIndents,
	getSaleAgent,
	postRetailerIndents,
} from "./retailerIndents.action";
import { v4 as uuidv4 } from "uuid";

const retailerIndentsSlice = createSlice({
	name: "retailerIndents",
	initialState: {
		loading: false,
		error: "",
		status: "",
		list: [],
		selectedData: {
			new_product_type: {},
		},
		count: 0,
		drawer: false,
		openAddressModal: false,
		openAddAddressModal: false,
		filterParams: {},
		productsList: [],
		saleAgents:[],
		saleAgentParams :{
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		productListparams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		pageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
	},
	reducers: {
		isDrawerVisible: (state, action) => {
			return {
				...state,
				drawer: action.payload,
			};
		},
		changeParams: (state, action) => {
			return {
				...state,
				pageParams: action.payload,
			};
		},
		changeProductListParams: (state, action) => {
			return {
				...state,
				productListparams: action.payload,
			};
		},
		changeSaleAgentParams: (state, action) => {
			return {
				...state,
				saleAgentParams: action.payload,
			};
		},
		setOpenAddressModal: (state, action) => {
			return {
				...state,
				openAddressModal: action.payload,
			};
		},
		setOpenAddAddressModal: (state, action) => {
			return {
				...state,
				openAddAddressModal: action.payload,
			};
		},
		changeFilterParams: (state, action) => {
			return {
				...state,
				filterParams: action.payload,
			};
		},
		FilterInputChangeValue: (state, action) => {
			return {
				...state,
				filterParams: {
					...state.filterParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},

		setSelectedData: (state, action) => {
			return {
				...state,
				selectedData: action.payload,
			};
		},
		inputChangeValue: (state, action) => {
			return {
				...state,
				selectedData: {
					...state.selectedData,
					...action.payload,
				},
			};
		},
		setAutoFillSaleAgent : (state, action) => {
			if(action.payload.dataToAutofill.length > 0) {
				const autoFillSaleAgent = action.payload.dataToAutofill[0]
			const changeDataSaleAgent = {
				"saleAgent_id": autoFillSaleAgent.id,
				"saleAgent" : autoFillSaleAgent
	};
			return{
				...state,
				selectedData: {
					...state.selectedData,
					...action.payload.dataToAutofill,
				},
			}
		}


		},
		addProductsToList: (state, action) => {
			return {
				...state,
				productsList: [...state.productsList, action.payload],
			};
		},
		removeProductsFromList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					return e.uniqueId == action.payload.product_id
						? {
								...e,
								dodelete: true,
						  }
						: e;
				}),
			};
		},

		changeProductList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					if (e.uniqueId == action.payload.product_id) {
						if (action.payload.key == "quantity") {
							const calculatedPrice =
								parseFloat(action.payload.value) *
								parseFloat(e.listedPrice) *
								parseFloat(e.unit.units);
							const discountAmt = e.discount_percent
								? +calculatedPrice * (+e.discount_percent / 100)
								: 0;
							var taxableValue = calculatedPrice - discountAmt;
							// var gstAmt = e.gst_percentage
							// 	? parseFloat(taxableValue) *
							// 	  (+e.gst_percentage / 100)
							// 	: 0;
							// var totalPrice =
							// 	parseFloat(taxableValue) + parseFloat(gstAmt);
							var totalPrice = parseFloat(taxableValue);

							// var amt_before_tax;
							// const tax_type = e?.tax_type;
							// const tax_rate = parseFloat(e.tax_percent) / 100;

							// switch (tax_type) {
							// 	case 1:
							// 		//inclusive
							// 		console.log("inclusive");
							// 		amt_before_tax =
							// 			parseFloat(taxableValue) /
							// 			(1 + tax_rate);
							// 		gstAmt = calculatedPrice - amt_before_tax;
							// 		totalPrice = taxableValue;

							// 		gstAmt = parseFloat(gstAmt).toFixed(2);
							// 		taxableValue =
							// 			parseFloat(amt_before_tax).toFixed(2);
							// 		totalPrice =
							// 			parseFloat(totalPrice).toFixed(2);
							// 		break;
							// 	case 2:
							// 		//exclusive
							// 		// taxableValue = calculatedPrice;
							// 		// tax_amount = taxableValue * tax_rate
							// 		// amt_after_tax = tax_amount + taxableValue;
							// 		// totalPrice = amt_after_tax - obj.discountAmt

							// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
							// 		// obj.taxableValue =
							// 		// 	parseFloat(taxableValue).toFixed(2);
							// 		// obj.price = parseFloat(totalPrice).toFixed(2);
							// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
							// 		break;
							// }
							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt: discountAmt.toFixed(2),
								// gstAmt: gstAmt.toFixed(2),
								price: totalPrice.toFixed(2),
								// taxableValue: taxableValue.toFixed(2),
								originalPrice: calculatedPrice.toFixed(2),
							};
						} else {
							const calculatedPrice =
								parseFloat(e.quantity) *
								parseFloat(e.listedPrice) *
								parseFloat(action.payload.value?.units);
							const discountAmt = e.discount_percent
								? +calculatedPrice * (+e.discount_percent / 100)
								: 0;

							var taxableValue = calculatedPrice - discountAmt;
							// var gstAmt = e.gst_percentage
							// 	? parseFloat(taxableValue) *
							// 	  (+e.gst_percentage / 100)
							// 	: 0;
							// var totalPrice =
							// 	parseFloat(taxableValue) + parseFloat(gstAmt);
							var totalPrice = parseFloat(taxableValue);

							// var amt_before_tax;
							// const tax_type = e?.tax_type;
							// const tax_rate = parseFloat(e.tax_percent) / 100;

							// switch (tax_type) {
							// 	case 1:
							// 		//inclusive
							// 		console.log("inclusive");
							// 		amt_before_tax =
							// 			parseFloat(taxableValue) /
							// 			(1 + tax_rate);
							// 		gstAmt = calculatedPrice - amt_before_tax;
							// 		totalPrice = taxableValue;

							// 		// gstAmt = parseFloat(gstAmt).toFixed(2);
							// 		// taxableValue =
							// 		// 	parseFloat(amt_before_tax).toFixed(2);
							// 		totalPrice =
							// 			parseFloat(totalPrice).toFixed(2);
							// 		break;
							// 	case 2:
							// 		//exclusive
							// 		// taxableValue = calculatedPrice;
							// 		// tax_amount = taxableValue * tax_rate
							// 		// amt_after_tax = tax_amount + taxableValue;
							// 		// totalPrice = amt_after_tax - obj.discountAmt

							// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
							// 		// obj.taxableValue =
							// 		// 	parseFloat(taxableValue).toFixed(2);
							// 		// obj.price = parseFloat(totalPrice).toFixed(2);
							// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
							// 		break;
							// }
							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt: discountAmt.toFixed(2),
								// gstAmt: gstAmt.toFixed(2),
								price: totalPrice.toFixed(2),
								// taxableValue: taxableValue.toFixed(2),
								originalPrice: calculatedPrice.toFixed(2),
							};
						}
					} else {
						return e;
					}
				}),
			};
		},
		clearProductsList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => ({
					...e,
					dodelete: true,
				})),
			};
		},
		clearData: (state, action) => {
			return {
				...state,
				loading: false,
				error: "",
				status: "",
				list: [],
				selectedData: {
					new_product_type: {},
				},
				count: 0,
				drawer: false,
				openAddressModal: false,
				openAddAddressModal: false,
				filterParams: {},
				productsList: [],
				productListparams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
				pageParams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder
			//get Data
			.addCase(getRetailerIndents.pending, (state, action) => {
				state.status = "getRetailerIndents loading";
				state.loading = true;
			})
			.addCase(getRetailerIndents.fulfilled, (state, action) => {
				state.status = "getRetailerIndents succeeded";
				state.loading = false;
				var noofpages = Math.ceil(
					action.payload.count / state.pageParams.page_size
				);
				state.count = action.payload.count;
				state.list = action.payload.results;
				state.pageParams = {
					...state.pageParams,
					no_of_pages: noofpages,
				};
			})
			.addCase(getRetailerIndents.rejected, (state, action) => {
				state.status = "getRetailerIndents failed";
				state.loading = false;
				state.error = action.error.message;
			})

			.addCase(getSaleAgent.pending, (state, action) => {
				state.status = "getSaleAgent loading";
				state.loading = true;
			})
			.addCase(getSaleAgent.fulfilled, (state, action) => {
				state.status = "getSaleAgent succeeded";
				state.loading = false;
				// var noofpages = Math.ceil(
				// 	action.payload.count / state.pageParams.page_size
				// );
				// state.count = action.payload.count;
				state.saleAgents = action.payload.sale_agents;
				// state.pageParams = {
				// 	...state.pageParams,
				// 	no_of_pages: noofpages,
				// };
			})
			.addCase(getSaleAgent.rejected, (state, action) => {
				state.status = "getSaleAgent failed";
				state.loading = false;
				state.error = action.error.message;
			})

			//get Data by Id
			.addCase(getRetailerIndentByID.pending, (state, action) => {
				state.status = "getRetailerIndentByID loading";
				state.loading = true;
			})
			.addCase(getRetailerIndentByID.fulfilled, (state, action) => {
				state.status = "getRetailerIndentByID succeeded";
				state.loading = false;
				state.selectedData = {
					...action.payload,
					// salesagent_id: action.payload.sales_agent?.id,
					distributor_id: action.payload.distributor?.id,
					retailer_id: action.payload.retailer?.id,
					location_id: action.payload.retailer?.location?.id,
					location_name: action.payload.retailer?.location?.name,
					billing_address_id:
						action.payload?.retailer?.default_billing_address?.id,
					shipping_address_id:
						action.payload?.retailer?.default_shipping_address?.id,
					default_billing_address:
						action.payload?.retailer?.default_billing_address,
					default_shipping_address:
						action.payload?.retailer?.default_shipping_address,
					saleAgent : action.payload.sales_agent
				};
				state.productsList = action.payload?.retailer_indent_items?.map(
					(e) => {
						const calculatedPrice =
							(e?.unit?.units ? parseFloat(e?.unit?.units) : 0) *
							parseFloat(e?.quantity) *
							parseFloat(e?.price);

						const discountAmt = e.discount_amount;
						var taxableValue = calculatedPrice - discountAmt;

						// var gstAmt =
						// 	parseFloat(taxableValue) * (+e.tax_percent / 100);
						// var totalPrice =
						// 	parseFloat(taxableValue) + parseFloat(gstAmt);

						var totalPrice = parseFloat(taxableValue);

						// const taxableValue = +e?.discount_percent
						// 	? calculatedPrice /
						// 	  (1 + parseFloat(e?.discount_percent))
						// 	: calculatedPrice;

						let freeItem = e?.free_status == 2 ? true : false;

						const obj = {
							...e,
							item_id: e.id,
							product_id: e.product.id,
							image: e.product.image,
							name: e.product.name,
							listedPrice: parseFloat(e.price).toFixed(2),
							originalPrice:
								parseFloat(calculatedPrice).toFixed(2),
							discountAmt: parseFloat(discountAmt).toFixed(2),
							// gstAmt: parseFloat(gstAmt).toFixed(2),
							// taxableValue: parseFloat(taxableValue).toFixed(2),
							price: parseFloat(totalPrice).toFixed(2),
							size: e?.unit?.id,
							// gst_percentage: e?.tax_percent,
							discount_percent: e?.discount_percent,
							quantity: parseInt(e?.quantity),
							unit: e?.unit,
							uniqueId: e.id,
							retailer_id: action.payload.retailer?.id,
							accepted_units: e?.accepted_units,
							freeItem: freeItem,
							dodelete: false,
							mrp: parseInt(e?.mrp)
								? parseFloat(e?.mrp).toFixed(2)
								: 0,
							showCancelIcon: true,
							// product_type: e.product.product_type,
						};

						if (freeItem) {
							obj.uniqueId = e?.applied_by?.id;
							obj.showCancelIcon = false;
						}

						// var amt_before_tax;
						// const tax_type = e?.tax_type;
						// const tax_rate = parseFloat(e.tax_percent) / 100;

						// switch (tax_type) {
						// 	case 1:
						// 		//inclusive
						// 		console.log("inclusive");
						// 		amt_before_tax =
						// 			parseFloat(taxableValue) / (1 + tax_rate);
						// 		gstAmt = calculatedPrice - amt_before_tax;
						// 		totalPrice = taxableValue;

						// 		obj.gstAmt = parseFloat(gstAmt).toFixed(2);
						// 		obj.taxableValue =
						// 			parseFloat(amt_before_tax).toFixed(2);
						// 		obj.price = parseFloat(totalPrice).toFixed(2);
						// 		break;
						// 	case 2:
						// 		//exclusive
						// 		// taxableValue = calculatedPrice;
						// 		// tax_amount = taxableValue * tax_rate
						// 		// amt_after_tax = tax_amount + taxableValue;
						// 		// totalPrice = amt_after_tax - obj.discountAmt

						// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
						// 		// obj.taxableValue =
						// 		// 	parseFloat(taxableValue).toFixed(2);
						// 		// obj.price = parseFloat(totalPrice).toFixed(2);
						// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
						// 		break;
						// }

						return { ...obj };
					}
				);
			})
			.addCase(getRetailerIndentByID.rejected, (state, action) => {
				state.status = "getRetailerIndentByID failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//post data
			.addCase(postRetailerIndents.pending, (state, action) => {
				state.status = "postRetailerIndents loading";
				state.loading = true;
			})
			.addCase(postRetailerIndents.fulfilled, (state, action) => {
				state.status = "postRetailerIndents succeeded";
				state.loading = false;
				state.selectedData = {};
			})
			.addCase(postRetailerIndents.rejected, (state, action) => {
				state.status = "postRetailerIndents failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//edit data
			.addCase(editRetailerIndents.pending, (state, action) => {
				state.status = "editRetailerIndents loading";
				state.loading = true;
			})
			.addCase(editRetailerIndents.fulfilled, (state, action) => {
				state.status = "editRetailerIndents succeeded";
				state.loading = false;
				state.selectedData = {};
			})
			.addCase(editRetailerIndents.rejected, (state, action) => {
				state.status = "editRetailerIndents failed";
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	isDrawerVisible,
	changeParams,
	changeProductListParams,
	changeSaleAgentParams,
	clearData,
	setSelectedData,
	inputChangeValue,
	addProductsToList,
	removeProductsFromList,
	changeProductList,
	setOpenAddressModal,
	setOpenAddAddressModal,
	changeFilterParams,
	FilterInputChangeValue,
	clearProductsList,
	setAutoFillSaleAgent
} = retailerIndentsSlice.actions;

export default retailerIndentsSlice.reducer;
