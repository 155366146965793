import { createSlice } from "@reduxjs/toolkit";
import {
  getMiniCity,
  getMiniRetailers,
  getMiniStates,
  getMiniUsers,
  getMiniSalesAgent,
  getUserPermissions,
  getMiniArea,
  getMiniLocation,
  getMiniZone,
  getMiniProducts,
  getMiniSizes,
  getMiniProductsType,
  getMiniSuperStockist,
  getMiniOrdersList,
  getMiniDistributors,
  getMiniCustomersList,
  getMiniReasonsList,
  getMiniDeliveryInstructionsList,
  getMiniDeliveryPersonsList,
  getMiniCashCollectionTimeslots,
  getMiniHub,
  getMiniRetailerIndents,
  getMiniRetailerReceipts,
  getMiniDistributorIndents,
  getMiniDistributorReceipt,
  getMiniRoutes,
  getMiniDrivers,
  getMiniSubscriptions,
} from "./miniActions";

const initialState = {
  userAccessList: [],
  miniStatesList: [],
  miniStateLoading: false,
  miniCityList: [],
  miniCityLoading: false,
  miniUserList: [],
  miniAreaList: [],
  miniAreaLoading: false,
  miniLocationList: [],
  miniLocationLoading: false,
  miniZoneList: [],
  miniZoneLoading: false,

  miniSuperStockist: {
    loading: false,
    list: [],
    count: 0,
    miniSuperStockistParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniRetailers: {
    loading: false,
    list: [],
    count: 0,
    miniRetailersParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniSalesAgent: {
    loading: false,
    list: [],
    count: 0,
    miniSalesAgentParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniDistributor: {
    loading: false,
    list: [],
    count: 0,
    miniDistributorParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniProducts: {
    loading: false,
    list: [],
    count: 0,
    miniParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniSizes: {
    loading: false,
    list: [],
    count: 0,
    miniParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniProductsType: {
    loading: false,
    list: [],
    count: 0,
    miniParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniOrders: {
    loading: false,
    list: [],
    count: 0,
    miniParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniCustomers: {
    loading: false,
    list: [],
    count: 0,
    miniParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniReasons: {
    loading: false,
    list: [],
    count: 0,
    miniParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniDeliveryInstructions: {
    loading: false,
    list: [],
    count: 0,
    miniParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniDeliveryPersons: {
    loading: false,
    list: [],
    count: 0,
    miniParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniCashCollectionTimeslots: {
    loading: false,
    list: [],
    count: 0,
    miniParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniHubs: {
    loading: false,
    list: [],
    count: 0,
    miniParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniRetailerIndents: {
    loading: false,
    list: [],
    count: 0,
    miniParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniRetailerReceipts: {
    loading: false,
    list: [],
    count: 0,
    miniParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniDistributorIndents: {
    loading: false,
    list: [],
    count: 0,
    miniParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniDistributorReceipt: {
    loading: false,
    list: [],
    count: 0,
    miniParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniRoutes: {
    loading: false,
    list: [],
    count: 0,
    miniParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniDrivers: {
    loading: false,
    list: [],
    count: 0,
    miniParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },
  miniSubscriptions: {
    loading: false,
    list: [],
    count: 0,
    miniParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }
  },

  // Params
  miniStatesParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },
  miniCityParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },
  miniAreaParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },
  miniLocationParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },
  miniZoneParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },
  miniUserParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },
};
const miniSlice = createSlice({
  name: "mini",
  initialState,
  reducers: {
    setMiniStatesParams: (state, action) => {
      return {
        ...state,
        miniStatesParams: action.payload,
      };
    },
    setMiniZoneParams: (state, action) => {
      return {
        ...state,
        miniZoneParams: action.payload,
      };
    },
    setMiniAreaParams: (state, action) => {
      return {
        ...state,
        miniAreaParams: action.payload,
      };
    },
    setMiniLocationParams: (state, action) => {
      return {
        ...state,
        miniLocationParams: action.payload,
      };
    },
    getMiniStateClearData: (state) => {
      return {
        ...state,
        miniStatesList: [],
      };
    },
    setMiniCityParams: (state, action) => {
      return {
        ...state,
        miniCityParams: action.payload,
      };
    },
    getMiniCityClearData: (state) => {
      return {
        ...state,
        miniCityList: [],
      };
    },
    setMiniUserParams: (state, action) => {
      return {
        ...state,
        miniUserParams: action.payload,
      };
    },
    setMiniSalesAgentParams: (state, action) => {
      return {
        ...state,
        miniSalesAgent: {
          ...state.miniSalesAgent,
          miniSalesAgentParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniSalesAgent: (state, action) => {
      return {
        ...state,
        ...initialState.miniSalesAgent
      };
    },
    setMiniDistributorParams: (state, action) => {
      return {
        ...state,
        miniDistributor: {
          ...state.miniDistributor,
          miniDistributorParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniDistributor: (state, action) => {
      return {
        ...state,
        ...initialState.miniDistributor
      };
    },
    setMiniRetailerParams: (state, action) => {
      return {
        ...state,
        miniRetailers: {
          ...state.miniRetailers,
          miniRetailersParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniRetailer: (state, action) => {
      return {
        ...state,
        ...initialState.miniRetailers
      };
    },
    setMiniSuperStockistParams: (state, action) => {
      return {
        ...state,
        miniSuperStockist: {
          ...state.miniSuperStockist,
          miniSuperStockistParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniSuperStockist: (state, action) => {
      return {
        ...state,
        ...initialState.miniSuperStockist
      };
    },
    setMiniProductParams: (state, action) => {
      return {
        ...state,
        miniProducts: {
          ...state.miniProducts,
          miniParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniProduct: (state, action) => {
      return {
        ...state,
        ...initialState.miniProducts
      };
    },
    setMiniSizesParams: (state, action) => {
      return {
        ...state,
        miniSizes: {
          ...state.miniSizes,
          miniParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniSizes: (state, action) => {
      return {
        ...state,
        ...initialState.miniSizes
      };
    },
    setMiniProductsTypeParams: (state, action) => {
      return {
        ...state,
        miniProductsType: {
          ...state.miniProductsType,
          miniParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniProductsType: (state, action) => {
      return {
        ...state,
        ...initialState.miniProductsType
      };
    },
    setMiniOrdersParams: (state, action) => {
      return {
        ...state,
        miniOrders: {
          ...state.miniOrders,
          miniParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniOrders: (state, action) => {
      return {
        ...state,
        ...initialState.miniOrders
      };
    },
    setMiniCustomersParams: (state, action) => {
      return {
        ...state,
        miniCustomers: {
          ...state.miniCustomers,
          miniParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniCustomers: (state, action) => {
      return {
        ...state,
        ...initialState.miniCustomers
      };
    },
    setMiniReasonsParams: (state, action) => {
      return {
        ...state,
        miniReasons: {
          ...state.miniReasons,
          miniParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniReasons: (state, action) => {
      return {
        ...state,
        ...initialState.miniReasons
      };
    },
    setMiniDeliveryInstructionsParams: (state, action) => {
      return {
        ...state,
        miniDeliveryInstructions: {
          ...state.miniDeliveryInstructions,
          miniParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniDeliveryInstructions: (state, action) => {
      return {
        ...state,
        ...initialState.miniDeliveryInstructions
      };
    },
    setMiniDeliveryPersonsParams: (state, action) => {
      return {
        ...state,
        miniDeliveryPersons: {
          ...state.miniDeliveryPersons,
          miniParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniDeliveryPersons: (state, action) => {
      return {
        ...state,
        ...initialState.miniDeliveryPersons
      };
    },
    setMiniCashCollectionTimeslotsParams: (state, action) => {
      return {
        ...state,
        miniCashCollectionTimeslots: {
          ...state.miniCashCollectionTimeslots,
          miniParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniCashCollectionTimeslots: (state, action) => {
      return {
        ...state,
        ...initialState.miniCashCollectionTimeslots
      };
    },
    setMiniHubParams: (state, action) => {
      return {
        ...state,
        miniHubs: {
          ...state.miniHubs,
          miniParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniHub: (state, action) => {
      return {
        ...state,
        ...initialState.miniHubs
      };
    },
    setMiniRetailerIndentsParams: (state, action) => {
      return {
        ...state,
        miniRetailerIndents: {
          ...state.miniRetailerIndents,
          miniParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniRetailerIndents: (state, action) => {
      return {
        ...state,
        ...initialState.miniRetailerIndents
      };
    },
    setMiniRetailerReceiptsParams: (state, action) => {
      return {
        ...state,
        miniRetailerReceipts: {
          ...state.miniRetailerReceipts,
          miniParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniRetailerReceipts: (state, action) => {
      return {
        ...state,
        ...initialState.miniRetailerReceipts
      };
    },
    setMiniDistributorIndentsParams: (state, action) => {
      return {
        ...state,
        miniDistributorIndents: {
          ...state.miniDistributorIndents,
          miniParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniDistributorIndents: (state, action) => {
      return {
        ...state,
        ...initialState.miniDistributorIndents
      };
    },
    setMiniDistributorReceiptParams: (state, action) => {
      return {
        ...state,
        miniDistributorReceipt: {
          ...state.miniDistributorReceipt,
          miniParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniDistributorReceipt: (state, action) => {
      return {
        ...state,
        ...initialState.miniDistributorReceipt
      };
    },
    setMiniRouteParams: (state, action) => {
      return {
        ...state,
        miniRoutes: {
          ...state.miniRoutes,
          miniParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniRoute: (state, action) => {
      return {
        ...state,
        ...initialState.miniRoutes
      };
    },
    setMiniDriverParams: (state, action) => {
      return {
        ...state,
        miniDrivers: {
          ...state.miniDrivers,
          miniParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniDriver: (state, action) => {
      return {
        ...state,
        ...initialState.miniDrivers
      };
    },
    setMiniSubscriptionParams: (state, action) => {
      return {
        ...state,
        miniSubscriptions: {
          ...state.miniSubscriptions,
          miniParams: {
            ...action.payload
          }
        },
      };
    },
    clearMiniSubscription: (state, action) => {
      return {
        ...state,
        ...initialState.miniSubscriptions
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserPermissions.pending, (state, action) => {
        state.status = "getUserPermissions loading";
        state.loading = true;
      })
      .addCase(getUserPermissions.fulfilled, (state, action) => {
        state.status = "getUserPermissions succeeded";
        state.loading = false;
        state.userAccessList = action.payload.response;
      })
      .addCase(getUserPermissions.rejected, (state, action) => {
        state.status = "getUserPermissions failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniStates.pending, (state, action) => {
        state.status = "getMiniStates loading";
        // if (state.miniStatesParams?.page == 1) {
        //   state.miniStateLoading = true;
        //   state.miniStatesList = [];
        // } else {
        state.miniStateLoading = true;
        // }
      })
      .addCase(getMiniStates.fulfilled, (state, action) => {
        state.status = "getMiniStates succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else {
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniStatesList, ...response.results];
        }
        // }

        var noofpages = Math.ceil(
          response.count / state.miniStatesParams?.page_size
        );
        state.miniStatesList = list;
        state.miniStateLoading = false;
        state.miniStatesParams = {
          ...state.miniStatesParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniStates.rejected, (state, action) => {
        state.status = "getMiniStates failed";
        state.miniStateLoading = false;
        state.error = action.error.message;
      })

      .addCase(getMiniCity.pending, (state, action) => {
        state.status = "getMiniCity loading";
        // if (state.miniCityParams?.page == 1) {
        //   state.miniCityLoading = true;
        //   state.miniCityList = [];
        // } else {
        state.miniCityLoading = true;
        // }
      })
      .addCase(getMiniCity.fulfilled, (state, action) => {
        state.status = "getMiniCity succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniCityList, ...response.results];
        }

        var noofpages = Math.ceil(
          response.count / state.miniCityParams?.page_size
        );
        state.miniCityList = list;
        state.miniCityLoading = false;
        state.miniCityParams = {
          ...state.miniCityParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniCity.rejected, (state, action) => {
        state.status = "getMiniCity failed";
        state.miniCityLoading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniArea.pending, (state, action) => {
        state.status = "getMiniArea loading";
        // if (state.miniAreaParams?.page == 1) {
        //   state.miniAreaLoading = true;
        //   state.miniAreaList = [];
        // } else {
        state.miniAreaLoading = true;
        // }
      })
      .addCase(getMiniArea.fulfilled, (state, action) => {
        state.status = "getMiniArea succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniAreaList, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniAreaParams?.page_size
        );
        state.miniAreaList = list;
        state.miniAreaLoading = false;
        state.miniAreaParams = {
          ...state.miniAreaParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniArea.rejected, (state, action) => {
        state.status = "getMiniArea failed";
        state.miniAreaLoading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniLocation.pending, (state, action) => {
        state.status = "getMiniLocation loading";
        // if (state.miniLocationParams?.page == 1) {
        //   state.miniLocationLoading = true;
        //   state.miniLocationList = [];
        // } else {
        state.miniLocationLoading = true;
        // }
      })
      .addCase(getMiniLocation.fulfilled, (state, action) => {
        state.status = "getMiniLocation succeeded";

        console.log(action.payload)
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          console.log(response.results)
          list = response.results;
        } else {
          list = [...state.miniLocationList, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniLocationParams?.page_size
        );
        state.miniLocationList = list;
        state.miniLocationLoading = false;
        state.miniLocationParams = {
          ...state.miniLocationParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniLocation.rejected, (state, action) => {
        state.status = "getMiniLocation failed";
        state.miniLocationLoading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniZone.pending, (state, action) => {
        state.status = "getMiniZone loading";
        // if (state.miniZoneParams?.page == 1) {
        //   state.miniZoneLoading = true;
        //   state.miniZoneList = [];
        // } else {
        state.miniZoneLoading = true;
        // }
      })
      .addCase(getMiniZone.fulfilled, (state, action) => {
        state.status = "getMiniZone succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniZoneList, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniZoneParams?.page_size
        );
        state.miniZoneList = list;
        state.miniZoneLoading = false;
        state.miniZoneParams = {
          ...state.miniZoneParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniZone.rejected, (state, action) => {
        state.status = "getMiniZone failed";
        state.miniZoneLoading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniUsers.pending, (state, action) => {
        state.status = "getMiniUsers loading";
        // if (state.miniUserParams?.page == 1) {
        //   state.loading = true;
        //   state.miniUserList = [];
        // } else {
        state.loading = true;
        // }
      })
      .addCase(getMiniUsers.fulfilled, (state, action) => {
        state.status = "getMiniUsers succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniUserList, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniUserParams?.page_size
        );
        state.miniUserList = list;
        state.loading = false;
        state.miniUserParams = {
          ...state.miniUserParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniUsers.rejected, (state, action) => {
        state.status = "getMiniUsers failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniRetailers.pending, (state, action) => {
        state.status = "getMiniRetailers loading";
        state.miniRetailers.loading = true;
        // state.miniRetailers.list = [];
      })
      .addCase(getMiniRetailers.fulfilled, (state, action) => {
        state.status = "getMiniRetailers succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniRetailers.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniRetailers.miniRetailersParams?.page_size
        );
        state.miniRetailers.list = list;
        state.miniRetailers.miniRetailersParams.no_of_pages = noofpages;
        state.miniRetailers.loading = false;
      })
      .addCase(getMiniRetailers.rejected, (state, action) => {
        state.status = "getMiniRetailers failed";
        state.miniRetailers.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniSuperStockist.pending, (state, action) => {
        state.status = "getMiniSuperStockist loading";
        state.miniRetailers.loading = true;
        // state.miniRetailers.list = [];

      })
      .addCase(getMiniSuperStockist.fulfilled, (state, action) => {
        state.status = "getMiniSuperStockist succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniSuperStockist.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniSuperStockist.miniSuperStockistParams?.page_size
        );
        state.miniSuperStockist.list = list;
        state.miniSuperStockist.miniSuperStockistParams.no_of_pages = noofpages;
        state.miniSuperStockist.loading = false;
      })
      .addCase(getMiniSuperStockist.rejected, (state, action) => {
        state.status = "getMiniSuperStockist failed";
        state.miniSuperStockist.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniSalesAgent.pending, (state, action) => {
        state.status = "getMiniSalesAgent loading";
        state.miniSalesAgent.loading = true;
        // state.miniSalesAgent.list = [];

      })
      .addCase(getMiniSalesAgent.fulfilled, (state, action) => {
        state.status = "getMiniSalesAgent succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniSalesAgent.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniSalesAgent.miniSalesAgentParams?.page_size
        );
        state.miniSalesAgent.list = list;
        state.miniSalesAgent.miniSalesAgentParams.no_of_pages = noofpages;
        state.miniSalesAgent.loading = false;
      })
      .addCase(getMiniSalesAgent.rejected, (state, action) => {
        state.status = "getMiniSalesAgent failed";
        state.miniSalesAgent.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniDistributors.pending, (state, action) => {
        state.status = "getMiniDistributors loading";
        state.miniDistributor.loading = true;
        // state.miniDistributor.list = [];

      })
      .addCase(getMiniDistributors.fulfilled, (state, action) => {
        state.status = "getMiniDistributors succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniDistributor.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniDistributor.miniDistributorParams?.page_size
        );
        state.miniDistributor.list = list;
        state.miniDistributor.count = response.count;
        state.miniDistributor.miniDistributorParams.no_of_pages = noofpages;
        state.miniDistributor.loading = false;
      })
      .addCase(getMiniDistributors.rejected, (state, action) => {
        state.status = "getMiniDistributors failed";
        state.miniDistributor.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniProducts.pending, (state, action) => {
        state.status = "getMiniProducts loading";
        state.miniProducts.loading = true;
        // state.miniProducts.list = [];

      })
      .addCase(getMiniProducts.fulfilled, (state, action) => {
        state.status = "getMiniProducts succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniProducts.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniProducts.miniParams?.page_size
        );
        state.miniProducts.list = list;
        state.miniProducts.miniParams.no_of_pages = noofpages;
        state.miniProducts.loading = false;
      })
      .addCase(getMiniProducts.rejected, (state, action) => {
        state.status = "getMiniProducts failed";
        state.miniProducts.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniSizes.pending, (state, action) => {
        state.status = "getMiniSizes loading";
        state.miniSizes.loading = true;
        // state.miniSizes.list = [];

      })
      .addCase(getMiniSizes.fulfilled, (state, action) => {
        state.status = "getMiniSizes succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniSizes.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniSizes.miniParams?.page_size
        );
        state.miniSizes.list = list;
        state.miniSizes.miniParams.no_of_pages = noofpages;
        state.miniSizes.loading = false;
      })
      .addCase(getMiniSizes.rejected, (state, action) => {
        state.status = "getMiniSizes failed";
        state.miniSizes.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniProductsType.pending, (state, action) => {
        state.status = "getMiniProductsType loading";
        state.miniProductsType.loading = true;
        // state.miniProductsType.list = [];

      })
      .addCase(getMiniProductsType.fulfilled, (state, action) => {
        state.status = "getMiniProductsType succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniProductsType.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniProductsType.miniParams?.page_size
        );
        state.miniProductsType.list = list;
        state.miniProductsType.miniParams.no_of_pages = noofpages;
        state.miniProductsType.loading = false;
      })
      .addCase(getMiniProductsType.rejected, (state, action) => {
        state.status = "getMiniProductsType failed";
        state.miniProductsType.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniOrdersList.pending, (state, action) => {
        state.status = "getMiniOrdersList loading";
        state.miniOrders.loading = true;
        // state.miniOrders.list = [];

      })
      .addCase(getMiniOrdersList.fulfilled, (state, action) => {
        state.status = "getMiniOrdersList succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniOrders.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniOrders.miniParams?.page_size
        );
        state.miniOrders.list = list;
        state.miniOrders.miniParams.no_of_pages = noofpages;
        state.miniOrders.loading = false;
      })
      .addCase(getMiniOrdersList.rejected, (state, action) => {
        state.status = "getMiniOrdersList failed";
        state.miniOrders.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniCustomersList.pending, (state, action) => {
        state.status = "getMiniCustomersList loading";
        state.miniCustomers.loading = true;
        // state.miniCustomers.list = [];

      })
      .addCase(getMiniCustomersList.fulfilled, (state, action) => {
        state.status = "getMiniCustomersList succeeded";
        var response = action.payload.response;
        const params = action.payload.params
        var list = [];
        // if (params.search) {
        //   list = response.results;
        //   // state.miniCustomers.miniParams.page = 1;
        // } else {
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniCustomers.list, ...response.results];
        }
        // }

        var noofpages = Math.ceil(
          response.count / state.miniCustomers.miniParams?.page_size
        );
        state.miniCustomers.list = list;
        state.miniCustomers.miniParams.no_of_pages = noofpages;
        state.miniCustomers.loading = false;
      })
      .addCase(getMiniCustomersList.rejected, (state, action) => {
        state.status = "getMiniCustomersList failed";
        state.miniCustomers.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniReasonsList.pending, (state, action) => {
        state.status = "getMiniReasonsList loading";
        state.miniReasons.loading = true;
        // state.miniReasons.list = [];

      })
      .addCase(getMiniReasonsList.fulfilled, (state, action) => {
        state.status = "getMiniReasonsList succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniReasons.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniReasons.miniParams?.page_size
        );
        state.miniReasons.list = list;
        state.miniReasons.miniParams.no_of_pages = noofpages;
        state.miniReasons.loading = false;
      })
      .addCase(getMiniReasonsList.rejected, (state, action) => {
        state.status = "getMiniReasonsList failed";
        state.miniReasons.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniDeliveryInstructionsList.pending, (state, action) => {
        state.status = "getMiniDeliveryInstructionsList loading";
        state.miniDeliveryInstructions.loading = true;
        // state.miniDeliveryInstructions.list = [];

      })
      .addCase(getMiniDeliveryInstructionsList.fulfilled, (state, action) => {
        state.status = "getMiniDeliveryInstructionsList succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniDeliveryInstructions.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniDeliveryInstructions.miniParams?.page_size
        );
        state.miniDeliveryInstructions.list = list;
        state.miniDeliveryInstructions.miniParams.no_of_pages = noofpages;
        state.miniDeliveryInstructions.loading = false;
      })
      .addCase(getMiniDeliveryInstructionsList.rejected, (state, action) => {
        state.status = "getMiniDeliveryInstructionsList failed";
        state.miniDeliveryInstructions.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniDeliveryPersonsList.pending, (state, action) => {
        state.status = "getMiniDeliveryPersonsList loading";
        state.miniDeliveryPersons.loading = true;
        // state.miniDeliveryPersons.list = [];

      })
      .addCase(getMiniDeliveryPersonsList.fulfilled, (state, action) => {
        state.status = "getMiniDeliveryPersonsList succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniDeliveryPersons.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniDeliveryPersons.miniParams?.page_size
        );
        state.miniDeliveryPersons.list = list;
        state.miniDeliveryPersons.miniParams.no_of_pages = noofpages;
        state.miniDeliveryPersons.loading = false;
      })
      .addCase(getMiniDeliveryPersonsList.rejected, (state, action) => {
        state.status = "getMiniDeliveryPersonsList failed";
        state.miniDeliveryPersons.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniCashCollectionTimeslots.pending, (state, action) => {
        state.status = "getMiniCashCollectionTimeslots loading";
        state.miniCashCollectionTimeslots.loading = true;
        // state.miniCashCollectionTimeslots.list = [];

      })
      .addCase(getMiniCashCollectionTimeslots.fulfilled, (state, action) => {
        state.status = "getMiniCashCollectionTimeslots succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniCashCollectionTimeslots.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniCashCollectionTimeslots.miniParams?.page_size
        );
        state.miniCashCollectionTimeslots.list = list;
        state.miniCashCollectionTimeslots.miniParams.no_of_pages = noofpages;
        state.miniCashCollectionTimeslots.loading = false;
      })
      .addCase(getMiniCashCollectionTimeslots.rejected, (state, action) => {
        state.status = "getMiniCashCollectionTimeslots failed";
        state.miniCashCollectionTimeslots.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniHub.pending, (state, action) => {
        state.status = "getMiniHub loading";
        state.miniHubs.loading = true;
        // state.miniHubs.list = [];

      })
      .addCase(getMiniHub.fulfilled, (state, action) => {
        state.status = "getMiniHub succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniHubs.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniHubs.miniParams?.page_size
        );
        state.miniHubs.list = list;
        state.miniHubs.miniParams.no_of_pages = noofpages;
        state.miniHubs.loading = false;
      })
      .addCase(getMiniHub.rejected, (state, action) => {
        state.status = "getMiniHub failed";
        state.miniHubs.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniRetailerIndents.pending, (state, action) => {
        state.status = "getMiniRetailerIndents loading";
        state.miniRetailerIndents.loading = true;
        // state.miniRetailerIndents.list = [];

      })
      .addCase(getMiniRetailerIndents.fulfilled, (state, action) => {
        state.status = "getMiniRetailerIndents succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniRetailerIndents.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniRetailerIndents.miniParams?.page_size
        );
        state.miniRetailerIndents.list = list;
        state.miniRetailerIndents.miniParams.no_of_pages = noofpages;
        state.miniRetailerIndents.loading = false;
      })
      .addCase(getMiniRetailerIndents.rejected, (state, action) => {
        state.status = "getMiniRetailerIndents failed";
        state.miniRetailerIndents.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniRetailerReceipts.pending, (state, action) => {
        state.status = "getMiniRetailerReceipts loading";
        state.miniRetailerReceipts.loading = true;
        // state.miniRetailerReceipts.list = [];

      })
      .addCase(getMiniRetailerReceipts.fulfilled, (state, action) => {
        state.status = "getMiniRetailerReceipts succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniRetailerReceipts.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniRetailerReceipts.miniParams?.page_size
        );
        state.miniRetailerReceipts.list = list;
        state.miniRetailerReceipts.miniParams.no_of_pages = noofpages;
        state.miniRetailerReceipts.loading = false;
      })
      .addCase(getMiniRetailerReceipts.rejected, (state, action) => {
        state.status = "getMiniRetailerReceipts failed";
        state.miniRetailerReceipts.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniDistributorIndents.pending, (state, action) => {
        state.status = "getMiniDistributorIndents loading";
        state.miniDistributorIndents.loading = true;
        // state.miniDistributorIndents.list = [];

      })
      .addCase(getMiniDistributorIndents.fulfilled, (state, action) => {
        state.status = "getMiniDistributorIndents succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniDistributorIndents.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniDistributorIndents.miniParams?.page_size
        );
        state.miniDistributorIndents.list = list;
        state.miniDistributorIndents.miniParams.no_of_pages = noofpages;
        state.miniDistributorIndents.loading = false;
      })
      .addCase(getMiniDistributorIndents.rejected, (state, action) => {
        state.status = "getMiniDistributorIndents failed";
        state.miniDistributorIndents.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniDistributorReceipt.pending, (state, action) => {
        state.status = "getMiniDistributorReceipt loading";
        state.miniDistributorReceipt.loading = true;
        // state.miniDistributorReceipt.list = [];

      })
      .addCase(getMiniDistributorReceipt.fulfilled, (state, action) => {
        state.status = "getMiniDistributorReceipt succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniDistributorReceipt.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniDistributorReceipt.miniParams?.page_size
        );
        state.miniDistributorReceipt.list = list;
        state.miniDistributorReceipt.miniParams.no_of_pages = noofpages;
        state.miniDistributorReceipt.loading = false;
      })
      .addCase(getMiniDistributorReceipt.rejected, (state, action) => {
        state.status = "getMiniDistributorReceipt failed";
        state.miniDistributorReceipt.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniRoutes.pending, (state, action) => {
        state.status = "getMiniRoutes loading";
        state.miniRoutes.loading = true;
        // state.miniRoutes.list = [];

      })
      .addCase(getMiniRoutes.fulfilled, (state, action) => {
        state.status = "getMiniRoutes succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniRoutes.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniRoutes.miniParams?.page_size
        );
        state.miniRoutes.list = list;
        state.miniRoutes.miniParams.no_of_pages = noofpages;
        state.miniRoutes.loading = false;
      })
      .addCase(getMiniRoutes.rejected, (state, action) => {
        state.status = "getMiniRoutes failed";
        state.miniRoutes.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniDrivers.pending, (state, action) => {
        state.status = "getMiniDrivers loading";
        state.miniDrivers.loading = true;
        // state.miniDrivers.list = [];

      })
      .addCase(getMiniDrivers.fulfilled, (state, action) => {
        state.status = "getMiniDrivers succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniDrivers.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniDrivers.miniParams?.page_size
        );
        state.miniDrivers.list = list;
        state.miniDrivers.miniParams.no_of_pages = noofpages;
        state.miniDrivers.loading = false;
      })
      .addCase(getMiniDrivers.rejected, (state, action) => {
        state.status = "getMiniDrivers failed";
        state.miniDrivers.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniSubscriptions.pending, (state, action) => {
        state.status = "getMiniSubscriptions loading";
        state.miniSubscriptions.loading = true;
        // state.miniSubscriptions.list = [];

      })
      .addCase(getMiniSubscriptions.fulfilled, (state, action) => {
        state.status = "getMiniSubscriptions succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniSubscriptions.list, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniSubscriptions.miniParams?.page_size
        );
        state.miniSubscriptions.list = list;
        state.miniSubscriptions.miniParams.no_of_pages = noofpages;
        state.miniSubscriptions.loading = false;
      })
      .addCase(getMiniSubscriptions.rejected, (state, action) => {
        state.status = "getMiniSubscriptions failed";
        state.miniSubscriptions.loading = false;
        state.error = action.error.message;
      })
  },
});

// Action creators are generated for each case reducer function
export const {
  setMiniStatesParams,
  setMiniZoneParams,
  setMiniAreaParams,
  setMiniLocationParams,
  getMiniStateClearData,
  setMiniCityParams,
  setMiniUserParams,
  setMiniSalesAgentParams,
  setMiniRetailerParams,
  setMiniSuperStockistParams,
  setMiniProductParams,
  setMiniSizesParams,
  setMiniProductsTypeParams,
  setMiniOrdersParams,
  setMiniDistributorParams,
  setMiniCustomersParams,
  setMiniReasonsParams,
  setMiniDeliveryInstructionsParams,
  setMiniDeliveryPersonsParams,
  setMiniCashCollectionTimeslotsParams,
  setMiniHubParams,
  setMiniRetailerIndentsParams,
  setMiniRetailerReceiptsParams,
  setMiniDistributorIndentsParams,
  setMiniDistributorReceiptParams,
  setMiniRouteParams,
  clearMiniSalesAgent,
  clearMiniDistributor,
  clearMiniRetailer,
  clearMiniSuperStockist,
  clearMiniProduct,
  clearMiniSizes,
  clearMiniProductsType,
  clearMiniOrders,
  clearMiniCustomers,
  clearMiniReasons,
  clearMiniDeliveryInstructions,
  clearMiniDeliveryPersons,
  clearMiniCashCollectionTimeslots,
  clearMiniHub,
  clearMiniRetailerIndents,
  clearMiniRetailerReceipts,
  clearMiniDistributorIndents,
  clearMiniDistributorReceipt,
  clearMiniRoute,
  setMiniDriverParams,
  clearMiniDriver,
  setMiniSubscriptionParams,
  clearMiniSubscription
} = miniSlice.actions;

export default miniSlice.reducer;
