import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAction, createSlice } from '@reduxjs/toolkit';
import moment from "moment";
import {
	addParams,
	getDownloadFile,
	getList,
	getParamsList,
	postAdd,
	postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";

export const getDemandSheet = createAsyncThunk(
	"/getDemandSheet",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/orders/demandsheet/list/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const postDemandSheet = createAsyncThunk(
	"/postDemandSheet",
	async (payload) => {
		try {
			const { data, history, form } = payload;
			const response = await postAdd("/orders/demandsheetcreate/", data);
			if (response.status === 201) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Demand Sheet Added Successfully.!
							</p>
						</div>
					),
				});
				history.push("/demand_sheet");
				form.resetFields();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Unable to add Demand Sheet. Please try
								again!
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);


export const postDraftDemandSheet = createAsyncThunk(
	"/postDraftDemandSheet",
	async (payload) => {
		
		try {
			const { data, history, form } = payload;
			const response = await postAdd("/orders/demand_sheet_draft/list/", data);
			if (response.status === 201) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Draft Added Successfully.!
							</p>
						</div>
					),
				});
				history.push("/demand_sheet");
				form.resetFields();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Unable to add Demand Sheet. Please try
								again!
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);


export const editDemandSheet = createAsyncThunk(
	"/editDemandSheet",
	async (payload) => {
		try {
			const { id, data, history, form } = payload;
			const response = await postEdit(
				`/orders/retailer/indentsdetail/${id}`,
				data
			);
			if (response.status === 200) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Demand Sheet Edited Successfully.!
							</p>
						</div>
					),
				});
				history.push("/retailer_indents");
				form.resetFields();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Unable to edit Demand Sheet. Please try
								again!
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);
export const getDemandSheetItemsBySuperStockist = createAsyncThunk(
	"/getDemandSheetItemsBySuperStockist",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/orders/demandsheetview/${params.id}`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getDemandSheetItemsByRoute = createAsyncThunk(
	"/getDemandSheetItemsByRoute",
	async (payload,{dispatch}) => {
		const params = addParams(payload);
		const {draftPayload} = payload;
		try {
			const response = await getParamsList(
				`/orders/demandsheetview/${params.id}`,
				params
			);
			if (response) {
				
				// return response;
				await dispatch(getDemandSheetItemsByRouteFulfilled(response))
				if(draftPayload?.product_type !== undefined ){
					await dispatch(getDemandSheetDraftItemsBySuperStockist(draftPayload))
				}
				
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const getDemandSheetDraftItemsBySuperStockist = createAsyncThunk(
	"/getDemandSheetDraftItemsBySuperStockist",
	async (payload,{getState,dispatch}) => {
		
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/orders/demand_sheet_draft/${params.id}/${params.product_type}/`,
				params
			);
			if (response) {
				const state = getState();
				const quantities = state.demandsheet.quantities;
				response.forEach(async (item) => {
					const foundItem = quantities.find(
						(q) =>
							q?.distributor_id === item?.distributor?.id &&
							q?.product_id === item?.product?.id &&
							q?.unit_name === item?.unit?.name
					);
	
					const data = {
						product_id: item.product.id,
						distributor_id: item.distributor.id,
						unit_id: item.unit.id,
						quantity: item.quantity,
						unit_units: item.unit.units,
					};

					// Apply free quantity logic here
					// applayFreeQuantity(data, item.unit.name);
				  await	dispatch(getDemandSheetProductSchemes({data}))

				});
				return response;

			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const getDemandSheetByID = createAsyncThunk(
	"/getDemandSheetByID",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/orders/demandsheet/details/${params.id}`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getDemandDraft = createAsyncThunk(
	"/getDemandDraft",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/orders/demanddraft/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const demandSheetExport = createAsyncThunk(
	"/getDemandDraft",
	async (payload) => {
		const params = addParams(payload);
		try {
			const demand_sheet_export_anchor_download = document.getElementById(
				"demand_sheet_export_anchor_download"
			);

			if (demand_sheet_export_anchor_download) {
				demand_sheet_export_anchor_download.remove();
			}
			const response = await getParamsList(
				`/orders/demand_sheet_export/${params.id}`,
				params
			);
			if (response) {
				console.log(response);
				const anchorElement = document.createElement("a");
				anchorElement.id = "demand_sheet_export_anchor_download";
				anchorElement.href = response;
				document.body.appendChild(anchorElement);
				anchorElement.click();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

// export const demandSheetExport = createAsyncThunk(
// 	"/demandSheetExport",
// 	async (payload) => {
// 		const params = addParams(payload);
// 		try {
// 			const response = await getDownloadFile(
// 				`/orders/demand_sheet_export/`,
// 				params
// 			);
// 			if (response) {
// 				return response;
// 			} else {
// 				throw new Error(response);
// 			}
// 		} catch (error) {
// 			return error.message;
// 		}
// 	}
// );

export const getDemandSheetProductSchemes = createAsyncThunk(
	"demandsheet/getDemandSheetProductSchemes",
	async (payload,{getState}) => {
		const { data, onSuccess } = payload;
		const state = getState();
		const body = {
			product_id: data.product_id,
			distributor_id: data.distributor_id,
			unit_id: data.unit_id,
			quantity: data.quantity,
		};
		try {
			const response = await postAdd(
				"/masters/distributor/schemes/",
				body

			);
			// onSuccess(response);
			return { response: response, data };
		} catch (error) {
			// handleError();
			// const keys = Object.keys(error?.response?.data);
			// const values = Object.values(error?.response?.data);
			// console.log(values);
			// Modal.error({
			// 	icon: (
			// 		<span className="remix-icon">
			// 			<RiAlertLine />
			// 		</span>
			// 	),
			// 	title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
			// 	content: (
			// 		<div>
			// 			<p className="hp-p1-body hp-text-color-black-80">
			// 				{Array.isArray(keys) && keys.length && keys[0]}:{" "}
			// 				{Array.isArray(values) && values.length
			// 					? values[0][0]
			// 					: error?.response?.data?.error.find((e) => e)
			// 					? error?.response?.data?.error.find((e) => e)
			// 					: error.message}
			// 			</p>
			// 		</div>
			// 	),
			// });
			throw error?.response?.data?.error.find((e) => e)
				? error?.response?.data?.error.find((e) => e)
				: error.message;
		}
	}
);

export const getDemandSheetItemsByRouteFulfilled = createAction('getDemandSheetItemsByRoute/fulfilled');