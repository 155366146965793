import { createSlice } from "@reduxjs/toolkit";
import { demandDraftExport, getDemandDraft } from "./demanddraft.action";
import { Input } from "antd";

const demanddraftSlice = createSlice({
	name: "demanddraft",
	initialState: {
		loading: false,
		error: "",
		status: "",
		list: [],
		selectedData: {
			new_product_type: {},
		},
		distributors: [],
		quantities: [],
		products: [],
		free_quantities: [],
		demanddraftsList: [],
		count: 0,
		drawer: false,
		openAddressModal: false,
		openAddAddressModal: false,
		filterParams: {},
		productsList: [],
		productListparams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		pageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
	},
	reducers: {
		isDrawerVisible: (state, action) => {
			return {
				...state,
				drawer: action.payload,
			};
		},
		changeDemandSheetList: (state, action) => {
			return {
				...state,
				demanddraftsList: state.demanddraftsList.map((e) => {
					return e.product_id == action.payload.product_id
						? {
								...e,
								quantity: action.payload.quantity,
						  }
						: {
								...e,
						  };
				}),
			};
		},
		changeParams: (state, action) => {
			return {
				...state,
				pageParams: action.payload,
			};
		},
		changeProductListParams: (state, action) => {
			return {
				...state,
				productListparams: action.payload,
			};
		},
		setOpenAddressModal: (state, action) => {
			return {
				...state,
				openAddressModal: action.payload,
			};
		},
		setOpenAddAddressModal: (state, action) => {
			return {
				...state,
				openAddAddressModal: action.payload,
			};
		},
		changeFilterParams: (state, action) => {
			return {
				...state,
				filterParams: action.payload,
			};
		},
		FilterInputChangeValue: (state, action) => {
			return {
				...state,
				filterParams: {
					...state.filterParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},

		setSelectedData: (state, action) => {
			return {
				...state,
				selectedData: action.payload,
			};
		},
		inputChangeValue: (state, action) => {
			return {
				...state,
				selectedData: {
					...state.selectedData,
					...action.payload,
				},
			};
		},
		addProductsToList: (state, action) => {
			return {
				...state,
				productsList: [...state.productsList, action.payload],
			};
		},
		removeProductsFromList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					return e.uniqueId == action.payload.product_id
						? {
								...e,
								dodelete: true,
						  }
						: {
								...e,
						  };
				}),
			};
		},
		changeProductList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					if (e.id == action.payload.product_id) {
						if (action.payload.key == "quantity") {
							const calculatedPrice =
								parseFloat(action.payload.value) *
								parseFloat(e.listedPrice) *
								parseFloat(e.unit.units);
							const discountAmt = e.discount_percent
								? +calculatedPrice * (+e.discount_percent / 100)
								: 0;
							const taxableValue = calculatedPrice - discountAmt;
							const gstAmt = e.gst_percentage
								? parseFloat(taxableValue) *
								  (+e.gst_percentage / 100)
								: 0;
							const totalPrice =
								parseFloat(taxableValue) + parseFloat(gstAmt);
							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt: discountAmt.toFixed(2),
								gstAmt: gstAmt.toFixed(2),
								price: totalPrice.toFixed(2),
								taxableValue: taxableValue.toFixed(2),
								originalPrice: calculatedPrice.toFixed(2),
							};
						} else {
							const calculatedPrice =
								parseFloat(e.quantity) *
								parseFloat(e.listedPrice) *
								parseFloat(action.payload.value?.units);
							const discountAmt = e.discount_percent
								? +calculatedPrice * (+e.discount_percent / 100)
								: 0;
							const taxableValue = calculatedPrice - discountAmt;
							const gstAmt = e.gst_percentage
								? parseFloat(taxableValue) *
								  (+e.gst_percentage / 100)
								: 0;
							const totalPrice =
								parseFloat(taxableValue) + parseFloat(gstAmt);
							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt: discountAmt.toFixed(2),
								gstAmt: gstAmt.toFixed(2),
								price: totalPrice,
								taxableValue: taxableValue.toFixed(2),
								originalPrice: calculatedPrice.toFixed(2),
							};
						}
					} else {
						return e;
					}
				}),
			};
		},
		clearData: (state, action) => {
			return {
				...state,
				loading: false,
				error: "",
				status: "",
				list: [],
				selectedData: {},
				count: 0,
				openAddressModal: false,
				openAddAddressModal: false,
				productsList: [],
				productListparams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder
			//get Data by superstockist id
			.addCase(getDemandDraft.pending, (state, action) => {
				state.status = "getDemandDraft loading";
				state.loading = true;
			})
			.addCase(getDemandDraft.fulfilled, (state, action) => {
				state.status = "getDemandDraft succeeded";
				state.loading = false;

				const {
					distributors = [],
					products = [],
					quantities = [],
					free_quantities = [],
				} = action.payload || {};
				let new_products = products.map((product) => {
					return {
						...product,
						units: [
							...new Set([
								...free_quantities
									.filter(
										(quantity) =>
											product.id === quantity.product_id
									)
									.map((quantity) => quantity.free_unit_name),
								...quantities
									.filter(
										(quantity) =>
											product.id === quantity.product_id
									)
									.map((quantity) => quantity.unit_name),
							]),
						],
					};
				});

				let new_quantities = [];

				new_products.forEach((product) => {
					product.units.forEach((unit_name) => {
						distributors.forEach((distributor) => {
							const new_free_quantities = free_quantities
								.filter(
									(quantity) =>
										product.id === quantity.product_id &&
										unit_name === quantity.free_unit_name &&
										quantity.distributor_id ===
											distributor.id
								)
								.map((quantity) => ({
									...quantity,
									applied_by_product_id:
										quantity.applied_by_product_id
											? quantity.applied_by_product_id
											: product.id,
									applied_by_unit_id:
										quantity.applied_by_unit_id
											? quantity.applied_by_unit_id
											: quantity.free_unit_id,
								}));

							if (new_free_quantities.length != 0) {
								const sum_new_free_quantities =
									new_free_quantities.reduce((acc, value) => {
										const temp_value = value.free_quantity
											? parseInt(value.free_quantity)
											: 0;
										return acc + temp_value;
									}, 0);

								new_quantities.push({
									free_quantity: sum_new_free_quantities,
									product_id: product.id,
									distributor_id: distributor.id,
									free_unit_name: unit_name,
									applied_by: new_free_quantities,
								});
							}
						});
					});
				});

				state.distributors = distributors;
				state.products = products;
				state.quantities = quantities;
				state.free_quantities = new_quantities;
			})
			.addCase(getDemandDraft.rejected, (state, action) => {
				state.status = "getDemandDraft failed";
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(demandDraftExport.pending, (state, action) => {
				state.status = "demandDraftExport loading";
				state.loading = true;
			})
			.addCase(demandDraftExport.fulfilled, (state, action) => {
				state.status = "demandDraftExport succeeded";
				state.loading = false;
			})
			.addCase(demandDraftExport.rejected, (state, action) => {
				state.status = "demandDraftExport failed";
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	isDrawerVisible,
	changeParams,
	changeProductListParams,
	clearData,
	setSelectedData,
	inputChangeValue,
	addProductsToList,
	removeProductsFromList,
	changeProductList,
	setOpenAddressModal,
	setOpenAddAddressModal,
	changeFilterParams,
	FilterInputChangeValue,
	changeDemandSheetList,
} = demanddraftSlice.actions;

export default demanddraftSlice.reducer;
