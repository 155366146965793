import { exact } from "prop-types";
import { lazy } from "react";

const PagesRoutes = [
	{
		path: "/login",
		component: lazy(() =>
			import("../../view/pages/authentication/login/username-login")
		),
		layout: "FullLayout",
	},
	{
		path: "/delete-account",
		component: lazy(() =>
			import("../../view/pages/authentication/deleteAcount/index")
		),
		layout: "FullLayout",
	},
	{
		path: "/dashboardB2B",
		component: lazy(() =>
			import("../../view/home/B2B/index.jsx")
		),
		layout: "VerticalLayout",
	},
	{
		path: "/dashboardB2C",
		component: lazy(() =>
			import("../../view/home/B2C/index.jsx")
		),
		layout: "VerticalLayout",
	},

	//masters
	{
		path: "/Masters",
		component: lazy(() => import("../../view/pages/masters")),
		layout: "VerticalLayout",
		exact: true,
	},

	{
		path: "/Masters/location",
		component: lazy(() =>
			import("../../view/pages/masters/location/index")
		),
		layout: "VerticalLayout",
		// exact: true,
	},
	{
		path: "/Masters/pricebook",
		component: lazy(() =>
			import("../../view/pages/masters/pricebook/index")
		),
		layout: "VerticalLayout",
		// exact: true,
	},
	{
		path: "/Masters/products",
		component: lazy(() =>
			import("../../view/pages/masters/products/index")
		),
		layout: "VerticalLayout",
		// exact: true,
	},
	{
		path: "/Masters/banners",
		component: lazy(() =>
			import("../../view/pages/masters/banners/index")
		),
		layout: "VerticalLayout",
		// exact: true,
	},
	{
		path: "/Masters/category",
		component: lazy(() =>
			import("../../view/pages/masters/category/index")
		),
		layout: "VerticalLayout",
		// exact: true,
	},
	{
		path: "/Masters/customer_pricebook",
		component: lazy(() =>
			import("../../view/pages/masters/customer_pricebook/index")
		),
		layout: "VerticalLayout",
		// exact: true,
	},
	{
		path: "/Masters/customer_offers",
		component: lazy(() =>
			import("../../view/pages/masters/customerSchemes/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/Masters/customer_offers/view/:id",
		component: lazy(() =>
			import("../../view/pages/masters/customerSchemes/view.customerSchemes.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/Masters/hub",
		component: lazy(() =>
			import("../../view/pages/masters/hub/index")
		),
		layout: "VerticalLayout",
		// exact: true,
	},
	{
		path: "/Masters/subscription_schemes",
		component: lazy(() =>
			import("../../view/pages/masters/subscriptionSchemes/index")
		),
		layout: "VerticalLayout",
		// exact: true,
	},
	{
		path: "/Masters/customer_delivery_instruction",
		component: lazy(() =>
			import("../../view/pages/masters/customerDeliveryInstruction/index")
		),
		layout: "VerticalLayout",
		// exact: true,
	},
	{
		path: "/Masters/ticket_type",
		component: lazy(() =>
			import("../../view/pages/masters/ticket_type/index")
		),
		layout: "VerticalLayout",
		// exact: true,
	},
	{
		path: "/Masters/b2bschemes",
		component: lazy(() =>
			import("../../view/pages/masters/B2BSchemes/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/Masters/b2bschemes/view/:id",
		component: lazy(() =>
			import("../../view/pages/masters/B2BSchemes/view.b2bSchemes.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/delivery_person",
		component: lazy(() =>
			import("../../view/pages/masters/deliveryPerson/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/delivery_person/:id",
		component: lazy(() =>
			import("../../view/pages/masters/deliveryPerson/DeliveryPersonAdd.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/delivery_person/view/:id",
		component: lazy(() =>
			import("../../view/pages/masters/deliveryPerson/DeliveryPersonView.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	// errors
	{
		path: "/pages/error-page-404",
		component: lazy(() => import("../../view/pages/error/404/index")),
		layout: "FullLayout",
	},
	{
		path: "/pages/error-page-403",
		component: lazy(() => import("../../view/pages/error/403/index")),
		layout: "FullLayout",
	},
	{
		path: "/pages/error-page-500",
		component: lazy(() => import("../../view/pages/error/500/index")),
		layout: "FullLayout",
	},
	{
		path: "/pages/error-page-502",
		component: lazy(() => import("../../view/pages/error/502/index")),
		layout: "FullLayout",
	},
	{
		path: "/pages/error-page-503",
		component: lazy(() => import("../../view/pages/error/503/index")),
		layout: "FullLayout",
	},
	{
		path: "/pages/blank-page",
		component: lazy(() => import("../../view/pages/blank")),
		layout: "VerticalLayout",
	},

	//  Side Menu
	{
		path: "/products",
		component: lazy(() => import("../../view/pages/Sidemenu/Products")),
		layout: "VerticalLayout",
	},
	{
		path: "/retailers",
		component: lazy(() => import("../../view/pages/Sidemenu/Retailers")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/retailers/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Retailers/AddRetailer")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/retailer_indents",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/RetailerIndents/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/retailer_indents/:id",
		component: lazy(() =>
			import(
				"../../view/pages/Sidemenu/RetailerIndents/RetailerIndentAdd"
			)
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/retailer_indents/view/:id",
		component: lazy(() =>
			import(
				"../../view/pages/Sidemenu/RetailerIndents/RetailerIndentView"
			)
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/distributor_indents",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/DistributorIndents/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/distributor_indents/view/:id",
		component: lazy(() =>
			import(
				"../../view/pages/Sidemenu/DistributorIndents/DistributoIndentView"
			)
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/distributor_orders",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/DisrtributorOrders/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/distributor_orders/view/:id",
		component: lazy(() =>
			import(
				"../../view/pages/Sidemenu/DisrtributorOrders/DistributorOrderView"
			)
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/sale_orders",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/SaleOrders/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/sale_orders/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/SaleOrders/saleOderView")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/receipts",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Receipts/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/receipts/add/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Receipts/ReceiptAdd")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/receipts/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Receipts/ReceiptView")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/retailer_receipts",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/RetilerReceipts/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/retailer_receipts/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/RetilerReceipts/ReceiptAdd")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/retailer_receipts/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/RetilerReceipts/ReceiptView")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/demand_draft",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/demandDraft/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/demand_sheet",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/demand_sheet/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/demand_sheet/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/demand_sheet/AddDemandSheet")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/demand_sheet/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/demand_sheet/ViewDemandSheet")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/distributor_indent_processing",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/distributor_indent_process/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/return_request",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/ReturnRequest")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/return_request/:id",
		component: lazy(() =>
			import(
				"../../view/pages/Sidemenu/ReturnRequest/ReturnRequestAdd.jsx"
			)
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/return_request/view/:id",
		component: lazy(() =>
			import(
				"../../view/pages/Sidemenu/ReturnRequest/ReturnRequestView.jsx"
			)
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/distributor_returns",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/DistributorReturns")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/distributor_returns/view/:id",
		component: lazy(() =>
			import(
				"../../view/pages/Sidemenu/DistributorReturns/DistributorReturnsView.jsx"
			)
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/superstockist_returns",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/SuperStockistReturns")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/superstockist_returns/view/:id",
		component: lazy(() =>
			import(
				"../../view/pages/Sidemenu/SuperStockistReturns/SuperStockistReturnView.jsx"
			)
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/return_sheet",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/return_sheet/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/return_sheet/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/return_sheet/AddReturnSheet")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/return_sheet/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/return_sheet/ViewReturnSheet")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/customers",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Customers/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/customers/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Customers/CustomerAdd.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/customers/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Customers/CustomerView.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/customer_orders",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/CustomerOrders/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/customer_orders/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/CustomerOrders/CustomerOrderAdd.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/customer_orders/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/CustomerOrders/CustomerOrderView.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/payments",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Payments/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/wallet",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Wallet/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/wallet/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Wallet/WalletView.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/vacationmode_request",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/VacationModeRequest/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/cash_collection",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/CashCollectionRequest/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/ticket_management",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/TicketManagement/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/subscription",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Subscription/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/subscription/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Subscription/SubscriptionView.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/subscription/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Subscription/SubscriptionAdd.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/driver",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Driver/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/driver/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Driver/DriverView.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/delivery_note",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/DeliveryNote/index")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/delivery_note/view/:id/:driver_id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/DeliveryNote/DeliveryNoteView.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},


	// Reports
	{
		path: "/reports",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/retailer_indent",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/RetailerIndents/index.jsx"
			)),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/retailer_receipts",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/RetailerReports/index.jsx"
			)),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/retailer_indent_item",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/RetailerIndentItem/index.jsx"
			)),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/retailer_receipt_item",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/RetailerReceiptItem/index.jsx"
			)),
		layout: "VerticalLayout",
		exact: true,
	},

	{
		path: "/reports/diioi",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/Diioi/index.jsx"
			)),
		layout: "VerticalLayout",
		exact: true,
	},

	{
		path: "/reports/distributor_receipt",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/DistributorReceipt/index.jsx"
			)),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/distributor_receipt_item",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/DistributorReceiptItem/index.jsx"
			)),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/distributor_indent",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/DistributorIndent/index.jsx"
			)),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/distributor_indent_item",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/DistributorIndentItem/index.jsx"
			)),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/order",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/Order/index.jsx")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/order_items",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/OrderItem/index.jsx")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/profile_mapping_report",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/ProfileMappingReport/index.jsx")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/retailer_address",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/RetailerAddress/index.jsx")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/retailer",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/Retailer/index.jsx")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/user",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/User/index.jsx")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/return_requests",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/ReturnRequests")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/return_requests_items",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/ReturnRequestItems/index.jsx")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/distributor_return",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/DistributorReturns")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/distributor_return_items",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/DistributorReturnItem")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/return",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/Return")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/return_item",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/ReturnItem")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/customer_order",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/CustomerOrder")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/customer_order_item",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/CustomerOrderItem")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/customer_scheme",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/CustomerScheme")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/payments",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/Payments")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/wallet",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/Wallet")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/subscription",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/Subscription")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/subscription_schedule",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/SubscriptionSchedule")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/superstockist_daily_value_report",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/SuperStockistDailyValueReport")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/indentwise_summary_report",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/IndentwiseSummaryReport")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/distributor_wise_receipt",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/DistributorWiseReceipt")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/superstockist_summary _report",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/SuperStockistSummaryReport")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/distributor_order",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/DistributorOrder")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/distributor_order_items",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/DistributorOrderItems")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/vacation_mode_request",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/VacationModeRequest")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/cash_collection_request",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/CashCollectionRequest")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/order_report_indent_wise",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/OrderReportIndentWise")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/route_wise_demand_sheet",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/RouteWiseDemandSheetView")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/subscription_schemes",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/SubscriptionScheme")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/wallet_balance_report",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/Wallet Balance")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/customer_order",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/CustomerOrder")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/today_deliveries",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/TodayDeliverables")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/customer_demand_sheet",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/CustomerDemandSheet")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/subscription_logs",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/SubscriptionLogs")),
		layout: "VerticalLayout",
		exact: true,
	},

	// // Settings View
	{
		path: "/pages/settings/",
		component: lazy(() => import("../../view/pages/settings")),
		layout: "VerticalLayout",
	},
	{
		path: "/pages/settings/user-details/:id",
		component: lazy(() =>
			import("../../view/pages/settings/ManageUsers/userDetails")
		),
		layout: "VerticalLayout",
	},

	//profile-view
	{
		path: "/pages/userProfile/personel-information",
		component: lazy(() => import("../../view/pages/userProfile")),
		layout: "VerticalLayout",
	},
	{
		path: "/pages/userProfile/activity",
		component: lazy(() => import("../../view/pages/userProfile")),
		layout: "VerticalLayout",
	},
	{
		path: "/pages/userProfile/password-change",
		component: lazy(() => import("../../view/pages/userProfile")),
		layout: "VerticalLayout",
	},
	{
		path: "/pages/userProfile/user-devices",
		component: lazy(() => import("../../view/pages/userProfile")),
		layout: "VerticalLayout",
	},
];

export default PagesRoutes;
